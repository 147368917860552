import { useState, useEffect } from "react";
import { useUser } from "../../context/UserProvider";
import http from "../../utils/http.utils";
import Modal from "../Modal";
import ModalHeader from "./ModalHeader";
import Loader from "../Loader";

export default function ModalFirstLogin() {
  const { user, persistUser } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [popup, setPopup] = useState(null);

  const getAttribute = (attributeName) => {
    return popup?.values[attributeName]?.[0]?.data;
  };

  const getPopup = async () => {
    try {
      const result = await http.get("/api/flat-popups");
      const popup = result["hydra:member"]?.[0];
      setPopup(popup);

      if (
        !user?.view?.includes(popup.values?.attribute_popup_link?.[0]?.data) &&
        popup.values?.attribute_popup_link?.[0]?.data
      ) {
        setIsOpen(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPopup();
  }, []);

  const pushView = async (newView) => {
    const views = [...user.view, newView];
    try {
      const result = await http.post("/api/user-views", {
        view: views,
      });
      await persistUser(result);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    pushView(getAttribute("attribute_popup_link"));
  };

  return (
    <Modal isOpen={isOpen}>
      <div
        className="flex flex-col p-4"
        style={{
          width: "80dvw",
        }}
      >
        <ModalHeader
          title={getAttribute("content_title")}
          onClose={handleClose}
        />
        <div className="bg-white p-2 flex flex-col gap-2">
          <div
            className="text-justify"
            dangerouslySetInnerHTML={{
              __html: getAttribute("content_description"),
            }}
          />
          {getAttribute("content_image") ? (
            <a
              href={getAttribute("attribute_popup_link")}
              target="_blank"
              style={{
                width: "50%",
              }}
            >
              <img src={getAttribute("content_image")?.url} alt="popup" />
            </a>
          ) : null}
        </div>
      </div>
    </Modal>
  );
}
